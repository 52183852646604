// Beetle icon from svgrepo.com
export const BeetleIcon = () => {
	return (
		<svg className='sound-source-icon' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
			viewBox="0 0 511.999 511.999">
			<g>
				<g>
					<path d="M252.993,437.322c-5.89,0-10.667,4.775-10.667,10.667v8.818c0,5.891,4.776,10.667,10.667,10.667
						c5.89,0,10.667-4.775,10.667-10.667v-8.818C263.66,442.098,258.884,437.322,252.993,437.322z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M444.627,272.485c0-5.891-4.777-10.667-10.667-10.667h-77.712v-53.002c28.979-0.503,56.144-12.021,76.685-32.574
						c4.164-4.167,4.162-10.921-0.005-15.085c-4.165-4.163-10.918-4.162-15.085,0.004c-16.509,16.519-38.318,25.795-61.595,26.297
						v-27.222c0-28.561-11.776-55.669-32.466-75.151V58.259c0-12.953-5.043-25.129-14.198-34.285l-20.85-20.85
						c-4.166-4.165-10.918-4.165-15.086,0c-4.165,4.165-4.165,10.919,0,15.085l20.851,20.85c5.127,5.127,7.95,11.946,7.95,19.2v11.366
						c-15.07-8.224-32.067-12.643-49.455-12.643c-17.405,0-34.418,4.426-49.503,12.662V58.259c0-7.141,2.9-14.141,7.948-19.198
						l20.778-20.766c4.166-4.164,4.169-10.918,0.004-15.085c-4.165-4.166-10.919-4.17-15.086-0.004l-20.786,20.775
						c-9.152,9.167-14.192,21.341-14.192,34.278v26.855c-20.668,19.482-32.43,46.579-32.43,75.123v30.513
						c-21.225-0.645-41.086-9.188-56.161-24.265c-4.166-4.165-10.918-4.165-15.086,0c-4.165,4.165-4.165,10.919,0,15.085
						c19.107,19.106,44.321,29.894,71.247,30.544v49.871H78.039c-5.89,0-10.667,4.776-10.667,10.667s4.777,10.667,10.667,10.667h71.687
						v56.46c-26.918,0.651-52.132,11.442-71.247,30.556c-4.165,4.165-4.165,10.919,0,15.085c2.083,2.083,4.813,3.124,7.542,3.124
						s5.459-1.042,7.542-3.124c15.084-15.084,34.944-23.631,56.161-24.276v5.503c0,39.938,17.135,77.604,49.555,108.927
						c24.119,23.303,48.103,34.9,49.112,35.382c1.454,0.694,3.026,1.042,4.599,1.042s3.145-0.348,4.601-1.043
						c1.008-0.482,24.989-12.078,49.106-35.382c32.415-31.323,49.548-68.989,49.548-108.927v-2.199
						c23.284,0.501,45.094,9.773,61.595,26.285c2.083,2.084,4.814,3.126,7.544,3.126c2.729,0,5.457-1.041,7.539-3.122
						c4.167-4.164,4.17-10.917,0.005-15.085c-20.532-20.545-47.698-32.059-76.685-32.562v-59.94h77.714v0.003
						C439.85,283.152,444.627,278.376,444.627,272.485z M171.06,160.238c0-23.978,10.46-46.675,28.695-62.272
						c14.818-12.671,33.724-19.649,53.237-19.649c19.46,0,38.313,6.945,53.103,19.554c0.108,0.094,0.212,0.19,0.323,0.279
						c18.111,15.589,28.494,38.198,28.494,62.089v28.605H171.06V160.238z M334.913,272.485v81.249v12.914
						c0,34.306-14.326,65.644-42.58,93.139c-15.744,15.322-31.718,25.188-39.342,29.469c-17.909-10.066-81.933-51.068-81.933-122.609
						v-16.232v-77.762v-62.477h71.267v198.533c0,5.891,4.777,10.667,10.667,10.667c5.89,0,10.667-4.775,10.667-10.667V210.176h71.254
						V272.485z"/>
				</g>
			</g>
		</svg>
	)
}

export const BirdIcon = () => {
	return (
		<svg className='sound-source-icon' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512">
			<g>
				<g>
					<path d="M510.404,110.013c-24.131-39.009-73.359-43.385-94.464-43.361c-5.281-17.663-21.59-30.351-40.897-30.351h-14.908
						c-38.81,0-70.382,31.579-70.382,70.394v39.759L3.124,433.084c-3.051,3.051-3.963,7.639-2.311,11.625
						c1.651,3.986,5.54,6.585,9.855,6.585h38.298c35.797,0,65.531-26.447,70.695-60.828h68.349l-18.057,25.798
						c-2.571,3.673-2.571,8.56,0,12.233l29.852,42.653c1.996,2.852,5.258,4.55,8.739,4.55h27.479c5.891,0,10.667-4.775,10.667-10.667
						c0-5.891-4.775-10.667-10.667-10.667h-21.925l-22.386-31.986l22.338-31.915h41.168l-18.049,25.8
						c-2.569,3.671-2.569,8.557,0,12.229l29.839,42.652c1.996,2.852,5.26,4.552,8.74,4.552h27.491c5.89,0,10.667-4.775,10.667-10.667
						c0-5.891-4.777-10.667-10.667-10.667h-21.936l-22.378-31.986l22.596-32.299c76.023-5.44,136.217-69.019,136.217-146.399v-92.809
						v-24.581h83.595c3.869,0,7.435-2.095,9.317-5.474C512.534,117.438,512.439,113.303,510.404,110.013z M48.964,429.959H36.418
						l39.494-39.494h22.061C93.069,413.015,72.963,429.959,48.964,429.959z M396.407,115.626v35.248v92.807h-0.002
						c0,69.173-56.283,125.45-125.461,125.45h-61.849c-0.362-0.018-0.723-0.018-1.084,0h-98.22H97.247l34.34-34.34h91.27
						c48.65,0,88.229-39.58,88.229-88.229c0-5.891-4.776-10.667-10.667-10.667s-10.667,4.775-10.667,10.667
						c0,36.886-30.01,66.896-66.896,66.896H152.92l155.042-155.042c0.036-0.036,0.066-0.076,0.101-0.112
						c0.209-0.214,0.413-0.435,0.603-0.667c0.113-0.137,0.211-0.281,0.316-0.422c0.102-0.138,0.209-0.272,0.305-0.415
						c0.109-0.162,0.203-0.331,0.302-0.497c0.079-0.132,0.162-0.261,0.236-0.398c0.09-0.166,0.165-0.338,0.245-0.509
						c0.068-0.145,0.141-0.289,0.203-0.437c0.068-0.164,0.124-0.333,0.183-0.5c0.058-0.161,0.119-0.321,0.17-0.485
						c0.051-0.169,0.089-0.339,0.131-0.51c0.042-0.166,0.089-0.332,0.123-0.501c0.039-0.198,0.063-0.399,0.092-0.599
						c0.02-0.144,0.048-0.287,0.063-0.433c0.035-0.353,0.053-0.706,0.053-1.06v-44.173c0-27.052,22.003-49.06,49.049-49.06h14.908
						c11.479,0,20.844,8.955,21.345,20.398c0.01,0.313,0.018,0.633,0.018,0.953V115.626z M417.738,104.958V88.046
						c13.885,0.216,39.925,2.705,59.324,16.912H417.738z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M364.6,78.954c-5.89,0-10.667,4.775-10.667,10.667v14.013c0,5.891,4.776,10.667,10.667,10.667s10.667-4.775,10.667-10.667
						V89.621C375.267,83.73,370.49,78.954,364.6,78.954z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M300.42,193.302c-5.89,0-10.667,4.775-10.667,10.667v6.453c0,5.891,4.777,10.667,10.667,10.667
						s10.667-4.775,10.667-10.667v-6.453C311.087,198.078,306.31,193.302,300.42,193.302z"/>
				</g>
			</g>
		</svg>
	)
}

export const FrogIcon = () => {
	return (
		<svg className='sound-source-icon' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
			viewBox="0 0 512 512">
			<g>
				<g>
					<path d="M163.575,120.725c-5.855,0-10.601,4.747-10.601,10.601c0,6.132-4.993,11.12-11.131,11.12
						c-6.132,0-11.121-4.988-11.121-11.12c0-5.854-4.746-10.601-10.601-10.601s-10.601,4.747-10.601,10.601
						c0,17.823,14.501,32.323,32.324,32.323c17.829,0,32.334-14.501,32.334-32.323C174.177,125.472,169.431,120.725,163.575,120.725z"
						/>
				</g>
			</g>
			<g>
				<g>
					<path d="M392.821,120.725c-5.855,0-10.601,4.747-10.601,10.601c0,6.132-4.993,11.12-11.131,11.12
						c-6.132,0-11.121-4.988-11.121-11.12c0-5.854-4.746-10.601-10.601-10.601s-10.601,4.747-10.601,10.601
						c0,17.823,14.501,32.323,32.324,32.323c17.829,0,32.334-14.501,32.334-32.323C403.422,125.472,398.676,120.725,392.821,120.725z"
						/>
				</g>
			</g>
			<g>
				<g>
					<path d="M228.785,149.518c-5.855,0-10.601,4.747-10.601,10.601v2.809c0,5.854,4.746,10.601,10.601,10.601
						s10.601-4.747,10.601-10.601v-2.809C239.386,154.265,234.64,149.518,228.785,149.518z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M279.947,149.518c-5.855,0-10.601,4.747-10.601,10.601v2.809c0,5.854,4.746,10.601,10.601,10.601
						s10.601-4.747,10.601-10.601v-2.809C290.549,154.265,285.802,149.518,279.947,149.518z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M502.444,261.1c-5.765-1.018-11.266,2.828-12.285,8.595c-0.229,1.295-0.482,2.606-0.76,3.931
						c-1.199,5.73,2.474,11.349,8.206,12.548c0.733,0.154,1.462,0.227,2.182,0.227c4.912,0,9.32-3.434,10.366-8.432
						c0.322-1.539,0.62-3.081,0.885-4.583C512.057,267.62,508.209,262.12,502.444,261.1z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M511.832,239.511c-2.985-16.696-11.316-24.532-17.782-28.165c-27.66-15.526-72.402,15.28-104.078,71.667
						c-1.905,3.389-3.67,6.937-5.313,10.599v-17.982c18.413-21.235,21.407-47.102,21.878-67.569v-0.01
						c0.112-5.056,0.077-9.851,0.043-14.49c-0.015-2.076-0.03-4.101-0.03-6.068c0-1.548-0.065-3.103-0.193-4.656
						c14.773-11.051,23.696-28.556,23.696-47.248c0-32.514-26.452-58.965-58.965-58.965c-20.968,0-40.316,11.357-50.796,29.063
						c-19.809-5.497-41.677-8.379-63.827-8.379c-22.143,0-44.009,2.884-63.816,8.379c-10.479-17.705-29.832-29.063-50.806-29.063
						c-32.513,0-58.965,26.452-58.965,58.965c0,18.688,8.923,36.192,23.7,47.244c-0.131,1.561-0.197,3.115-0.197,4.66
						c0,1.967-0.015,3.992-0.03,6.068c-0.034,4.639-0.069,9.434,0.043,14.5c0.472,20.473,3.468,46.35,21.889,67.582v17.968
						c-1.643-3.655-3.398-7.208-5.302-10.596c-31.676-56.389-76.42-87.203-104.082-71.667c-14.383,8.08-20.904,26.603-18.362,52.154
						c2.241,22.531,11.275,48.815,25.433,74.009c17.388,30.961,51.588,58.899,76.808,76.662H38.436
						c-5.855,0-10.601,4.747-10.601,10.601c0,5.854,4.746,10.601,10.601,10.601h100.3c0.014,0,0.028-0.002,0.041-0.002
						c0.011,0,0.022,0.002,0.033,0.002s0.022-0.002,0.033-0.002c0.014,0,0.028,0.002,0.041,0.002h235.171
						c0.014,0,0.029-0.002,0.042-0.002s0.029,0.002,0.042,0.002h100.311c5.855,0,10.601-4.747,10.601-10.601
						c0-5.854-4.746-10.601-10.601-10.601h-64.279c25.22-17.763,59.419-45.701,76.807-76.661c5.292-9.416,9.909-19.062,13.724-28.669
						c2.159-5.443-0.5-11.605-5.942-13.766c-5.441-2.161-11.604,0.5-13.765,5.941c-3.466,8.73-7.671,17.515-12.501,26.11
						c-18.463,32.873-60.484,63.401-83.603,78.512c0.88-27.607,5.094-79.374,23.565-112.242c27.992-49.831,62.672-70.606,75.209-63.566
						c4.499,2.528,6.45,8.689,7.295,13.412c1.03,5.762,6.539,9.597,12.302,8.569C509.027,250.783,512.864,245.276,511.832,239.511z
						M44.456,327.125c-12.757-22.7-20.86-46.041-22.818-65.721c-1.609-16.174,1.25-27.976,7.647-31.569
						c1.583-0.889,3.518-1.335,5.748-1.335c15.439,0,45.004,21.358,69.464,64.899c0,0.001,0,0.001,0,0.001
						c18.55,33.01,22.722,85.017,23.581,112.261C104.971,390.569,62.955,360.063,44.456,327.125z M127.528,201.539
						c-0.006-2.648,0.004-5.266,0.022-7.823c0.016-2.129,0.031-4.207,0.031-6.223c0-2.482,0.278-5.033,0.825-7.583
						c0.971-4.528-1.109-9.162-5.137-11.445c-11.835-6.712-19.188-19.308-19.188-32.877c0-20.822,16.94-37.762,37.762-37.762
						c15.589,0,29.78,9.798,35.313,24.381c2.011,5.299,7.832,8.079,13.219,6.311c19.944-6.549,42.798-10.009,66.09-10.009
						c23.3,0,46.156,3.461,66.1,10.009c5.387,1.769,11.208-1.012,13.219-6.311c5.534-14.583,19.721-24.381,35.303-24.381
						c20.822,0,37.762,16.94,37.762,37.762c0,13.572-7.349,26.17-19.18,32.876c-4.029,2.284-6.109,6.919-5.137,11.447
						c0.54,2.516,0.814,5.066,0.814,7.581c0,2.016,0.015,4.094,0.031,6.223c0.019,2.558,0.029,5.178,0.022,7.828
						c-15.306,8.113-62.131,30.187-96.601,17.069c-20.846-7.937-43.81-7.936-64.664-0.001
						C189.651,231.733,142.813,209.643,127.528,201.539z M177.272,414.172h-27.786V293.263c8.109,4.908,17.383,9.014,27.786,12.279
						V414.172z M316.416,414.172H198.475V310.756c16.864,3.162,36.293,4.77,57.99,4.77c22.542,0,42.629-1.733,59.951-5.146V414.172z
						M363.455,414.172h-25.836V304.918c9.612-3.156,18.234-7.055,25.836-11.659V414.172z M366.424,264.171
						c-9.692,10.023-23.889,17.639-42.202,22.638c-18.237,4.986-41.034,7.513-67.757,7.513c-25.834,0-48.013-2.363-65.926-7.028
						c-19.22-4.994-34.035-12.774-44.037-23.127c-10.859-11.221-15.504-24.765-17.496-38.204c23.62,10.92,66.786,26.119,102.668,12.463
						c15.99-6.085,33.597-6.086,49.58,0.001c10.134,3.856,20.844,5.41,31.537,5.41c27.162,0,54.176-10.035,71.13-17.871
						C381.929,239.407,377.284,252.95,366.424,264.171z"/>
				</g>
			</g>
		</svg>
	)
}

export const OwlIcon = () => {
	return (
		<svg className='sound-source-icon' version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512.001 512.001">
			<g>
				<g>
					<path d="M328.105,287.731c-5.806-0.466-10.902,3.863-11.371,9.674c-0.095,1.183-0.197,2.362-0.302,3.536
						c-0.521,5.807,3.763,10.937,9.571,11.458c0.321,0.029,0.639,0.043,0.955,0.043c5.403,0,10.009-4.128,10.502-9.614
						c0.111-1.238,0.217-2.48,0.319-3.728C338.247,293.291,333.916,288.2,328.105,287.731z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M191.134,106.044c-16.732,0-30.345,13.613-30.345,30.344c0,16.732,13.613,30.345,30.345,30.345
						s30.345-13.613,30.345-30.345C221.479,119.657,207.866,106.044,191.134,106.044z M191.135,145.62c-5.09,0-9.232-4.141-9.232-9.232
						s4.141-9.231,9.232-9.231c5.09,0,9.232,4.14,9.232,9.231S196.225,145.62,191.135,145.62z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M320.866,106.044c-16.731,0-30.344,13.613-30.344,30.344c0,16.732,13.613,30.345,30.344,30.345
						c16.732,0,30.344-13.613,30.344-30.345C351.21,119.657,337.597,106.044,320.866,106.044z M320.866,145.62
						c-5.089,0-9.231-4.141-9.231-9.232s4.141-9.231,9.231-9.231s9.231,4.14,9.231,9.231S325.956,145.62,320.866,145.62z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M392.792,433.058h-75.499c44.079-32.422,72.577-98.13,72.577-171.147c0-21.141-2.356-41.867-7.001-61.673
						c8.907-18.778,13.421-38.926,13.421-59.949c0-10.726-1.228-21.379-3.624-31.701c-0.087-0.666-0.233-1.333-0.454-1.993
						c-0.024-0.072-0.05-0.146-0.074-0.215c-7.387-29.755-24.694-56.69-48.813-75.895C318.279,10.541,288.083,0,256,0
						c-32.071,0-62.26,10.536-87.303,30.47c-24.224,19.282-41.587,46.353-48.92,76.231c-0.052,0.186-0.096,0.372-0.137,0.558
						c-2.603,10.718-3.928,21.828-3.928,33.03c0,21.026,4.515,41.172,13.421,59.947c-4.645,19.808-7.001,40.535-7.001,61.675
						c0,73.018,28.498,138.725,72.578,171.147h-75.5c-5.83,0-10.557,4.726-10.557,10.557c0,5.831,4.726,10.557,10.557,10.557h79.632
						l-7.151,19.844c-3.132,8.677-1.836,18.366,3.467,25.918c5.305,7.555,13.982,12.066,23.212,12.066H256h37.63
						c9.23,0,17.907-4.511,23.212-12.066c5.303-7.552,6.599-17.241,3.468-25.914l-7.153-19.849h79.633
						c5.829,0,10.557-4.726,10.557-10.557C403.348,437.785,398.623,433.058,392.792,433.058z M256,21.113
						c34.195,0,65.477,14.369,87.425,38.031c-7.18-2.258-14.769-3.47-22.559-3.47c-20.141,0-39.082,7.841-53.341,22.088
						c-4.496,4.5-8.35,9.469-11.525,14.794c-3.176-5.327-7.032-10.298-11.531-14.802c-14.253-14.239-33.194-22.081-53.335-22.081
						c-7.791,0-15.38,1.212-22.561,3.471C190.523,35.483,221.806,21.113,256,21.113z M140.093,112.519
						c7.79-21.391,28.232-35.731,51.042-35.731c14.505,0,28.147,5.648,38.405,15.896c10.256,10.266,15.904,23.907,15.904,38.413v45.531
						c0,5.831,4.726,10.557,10.557,10.557c5.829,0,10.557-4.726,10.557-10.557v-45.531c0-14.506,5.649-28.147,15.897-38.405
						c10.266-10.256,23.907-15.904,38.413-15.904c22.828,0,43.274,14.358,51.052,35.778c2.157,9.004,3.257,18.327,3.257,27.724
						c0,19-4.331,37.147-12.874,53.938c-9.168,18.034-22.897,33.561-39.707,44.902c-19.696,13.295-42.724,20.323-66.594,20.323
						c-23.87,0-46.897-7.028-66.596-20.325c-16.798-11.335-30.521-26.85-39.691-44.874c-0.056-0.107-0.103-0.219-0.161-0.323
						c-8.443-16.707-12.728-34.751-12.728-53.641C136.825,130.867,137.927,121.534,140.093,112.519z M165.977,364.611
						c-14.871-29.854-22.733-65.367-22.733-102.7c0-11.858,0.828-23.555,2.413-35.005c7.81,9.933,16.953,18.807,27.159,26.334
						c-0.054,2.958-0.083,5.835-0.083,8.671c0,49.425,7.716,96.16,21.727,131.595c2.832,7.162,5.881,13.736,9.122,19.701
						C188.98,401.408,176.001,384.733,165.977,364.611z M245.444,490.887h-27.075c-3.399,0-5.281-2.158-5.933-3.086
						c-0.65-0.926-2.039-3.426-0.886-6.62l9.733-27.009h24.16V490.887z M299.564,487.801c-0.651,0.928-2.534,3.086-5.933,3.086h-27.074
						v-36.715h24.16l9.733,27.014C301.603,484.376,300.214,486.875,299.564,487.801z M308.469,413.164
						c11.243-20.664,20.056-48.428,25.418-81.678c0.929-5.755-2.984-11.174-8.741-12.103c-5.756-0.928-11.174,2.985-12.102,8.741
						c-9.845,61.039-33.054,104.187-56.483,104.929c-0.185,0.006-0.378,0.004-0.562,0.004c-0.184,0-0.376,0.002-0.605-0.005
						c-28.706-0.91-60.642-69.481-61.528-166.984c19.217,9.512,40.376,14.497,62.132,14.497c21.757,0,42.916-4.985,62.133-14.496
						c-0.016,1.69-0.038,3.375-0.073,5.042c-0.119,5.828,4.509,10.652,10.338,10.771c0.074,0.001,0.148,0.002,0.221,0.002
						c5.729,0,10.432-4.586,10.55-10.34c0.065-3.175,0.098-6.416,0.098-9.633c0-2.796-0.031-5.703-0.084-8.671
						c10.207-7.528,19.35-16.402,27.16-26.335c1.586,11.45,2.413,23.147,2.413,35.005c0,37.334-7.862,72.846-22.733,102.701
						C336.012,384.709,323.05,401.369,308.469,413.164z"/>
				</g>
			</g>
		</svg>
	)
}

export const CarIcon = () => {
	return (
		<svg className='sound-source-icon' viewBox="0 0 846.66 846.66" version="1.1" xmlns="http://www.w3.org/2000/svg">
			<g id="Layer_x0020_1">
				<path className="fil0" d="M136.31 580.84l-107.69 0c-11.48,0 -20.78,-9.3 -20.78,-20.78l0 -8.4c0,-103.85 84.89,-188.74 188.74,-188.74l11.88 0c10.5,-92.79 89.79,-163.76 183.36,-163.76l121.79 0c93.57,0 172.85,70.97 183.36,163.76l32.89 0c59.97,0 108.96,48.99 108.96,108.96 0,59.97 -48.98,108.96 -108.96,108.96l-19.51 0c-9.51,39.04 -44.63,66.65 -84.94,66.65 -40.32,0 -75.43,-27.61 -84.95,-66.65l-234.26 0c-9.52,39.04 -44.63,66.65 -84.95,66.65 -40.31,0 -75.42,-27.61 -84.94,-66.65zm244.37 -41.56l0 -134.8 -184.1 0c-76.15,0 -140.26,59.04 -146.66,134.8l86.39 0c9.52,-39.04 44.62,-66.65 84.94,-66.65 40.32,0 75.43,27.61 84.95,66.65l74.48 0zm41.56 -134.8l0 134.8 118.22 0c9.52,-39.04 44.63,-66.65 84.95,-66.65 40.31,0 75.43,27.61 84.94,66.65l19.51 0c37.03,0 67.4,-30.38 67.4,-67.4 0,-37.03 -30.37,-67.4 -67.4,-67.4 -102.54,0 -205.08,0 -307.62,0zm51.26 -163.76l0 122.2 181.56 0c-10.28,-69.72 -70.79,-122.2 -141.45,-122.2l-40.11 0zm-41.57 122.2l0 -122.2 -40.11 0c-70.66,0 -131.17,52.48 -141.45,122.2l181.56 0zm-210.68 151.27c-25.33,0 -45.87,20.54 -45.87,45.87 0,25.33 20.54,45.87 45.87,45.87 25.34,0 45.88,-20.53 45.88,-45.87 0,-25.33 -20.54,-45.87 -45.88,-45.87zm404.16 0c-25.34,0 -45.88,20.54 -45.88,45.87 0,25.33 20.54,45.87 45.88,45.87 25.33,0 45.87,-20.53 45.87,-45.87 0,-25.33 -20.54,-45.87 -45.87,-45.87z"/>
			</g>
		</svg>
	)
}