// Info icon from feathericons

const InfoIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="1.5" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            className="info-icon">
            <circle cx="12" cy="12" r="10"/>
            <line x1="12" y1="17" x2="12" y2="11"/>
            <line x1="12" y1="8" x2="12.01" y2="8"/>
        </svg>
    )
}

export default InfoIcon;